import React, { useCallback, useState } from 'react';
import { string, object, func } from 'prop-types';
import classNames from 'classnames';
import css from './CategoryFilter.module.css';
import { CategoryFilterItem } from './CategoryFilter.Item';
import Icon from '../Icon/Icon';
import { useEffect } from 'react';

export const CategoryFilterPrimaryItem = (props) => {
    const { category, selectedKey, onSelect } = props;
    const [ isExpanded, setIsExpanded ] = useState(false);

    const classes = classNames(css.category, css.parentCategory, {
        [css.selectedCategory]: selectedKey === category.key
    });

    const hasChildItems = category.items?.length > 0;

    const handleSelect = useCallback(() => {
        if (hasChildItems) {
            setIsExpanded(!isExpanded);
        }
        onSelect(category.key);
    }, [hasChildItems, isExpanded, category.key, onSelect]);

    useEffect(() => {
        if (hasChildItems) {
            const selectedChild = category.items.find(childCategory => childCategory.key === selectedKey);
            if (selectedChild) {
                setIsExpanded(true);
            }
        }
    }, []);

    return (
        <div>
            <div className={classes} onClick={handleSelect}>
                {category.label}
                {hasChildItems && <Icon
                    className={css.arrowIcon}
                    iconID={isExpanded ? 'arrowUp' : 'arrowDown'}
                />}
            </div>
            {isExpanded && category.items?.map(childCategory => (
                <CategoryFilterItem
                    key={childCategory.key}
                    category={childCategory}
                    selectedKey={selectedKey}
                    className={css.childCategory}
                    onSelect={onSelect}
                />
            ))}
        </div>
    );
}

CategoryFilterPrimaryItem.propTypes = {
    category: object.isRequired,
    selectedKey: string,
    onSelect: func.isRequired
};

export default CategoryFilterPrimaryItem;