import { filters } from '../../marketplace-custom-config';

export const getFilterConfig = (id) => {
    const config = filters.find(item => item.id === id);
    return {
        paramName: config.queryParamNames[0],
        options: config.config.options
    };
}

export const parseInitialValues = (initialValues, config) => {
    const value = initialValues?.([config.paramName])?.[config.paramName];
    const keys = value?.replace('has_any:', '').split(',') ?? [];
    return config.options.filter(option => keys.includes(option.key));
}

export const generateQueryValue = (values, config, prefix) => {
    const allKeys = Object.keys(values);
    const keys = allKeys.filter(key => {
        return values[key].length === 1 && !!config.options.find(option => option.key === key);
    });
    return keys.length > 0 ? `${prefix || ''}${keys.join(',')}` : '';
}