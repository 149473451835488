import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import Modal from '../Modal/Modal';
import Icon from '../Icon/Icon';
import css from './CategoryFilter.module.css';
import { CategoryFilterPrimaryItem } from './CategoryFilter.PrimaryItem';
import { getCategoryByKey, getCategoryByQueryValue, getQueryValueForCategory } from './CategoryFilter.helpers';

export const CategoryFilter = (props) => {
  const {
    options,
    initialValues,
    queryParamNames,
    onSelect,
    onManageDisableScrolling,
    intl
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [selectedKey, setSelectedKey] = useState('');

  const queryParamName = Array.isArray(queryParamNames) ? queryParamNames[0] : queryParamNames;
  const initialCategory = useMemo(() => {
    const value = initialValues?.[queryParamName];
    return getCategoryByQueryValue(options, value);
  }, [initialValues?.[queryParamName], options]);

  const labelClassName = initialCategory ? css.labelSelected : css.label;
  const labelText = initialCategory?.label || intl.formatMessage({ id: 'CategoryFilter.rentals' });

  const openModal = useCallback(() => {
    setIsOpen(true);
  }, []);

  const handleClear = useCallback(() => {
    setSelectedKey('');
    setIsOpen(false);
    onSelect({ [queryParamName]: '' });
  }, [onSelect, queryParamName]);

  const handleApply = useCallback(() => {
    setSelectedKey('');
    setIsOpen(false);
    const selectedCategory = getCategoryByKey(options, selectedKey);
    const queryValue = getQueryValueForCategory(selectedCategory);
    onSelect({ [queryParamName]: queryValue });
  }, [options, onSelect, queryParamName, selectedKey]);

  const handleCancel = useCallback(() => {
    setSelectedKey('');
    setIsOpen(false);
  }, []);

  return (
    <>
      <div className={css.root}>
        <button
          className={labelClassName}
          onClick={openModal}
          title={labelText}
        >
          <Icon className={css.rentalsIcon} iconID="rentalsSmallButton" />
          <span className={css.labelText}>
            {labelText}
          </span>
        </button>
      </div>
      <Modal
        id="categoryFilterModal"
        isOpen={isOpen}
        closeButtonMessage={' '}
        scrollLayerClassName={css.modalLayer}
        containerClassName={css.modalContainer}
        closeButtonClassName={css.modalClose}
        onManageDisableScrolling={onManageDisableScrolling}
        onClose={handleCancel}
        usePortal
        showCloseButton
      >
        <div>
          <div className={css.modalTitle}>
            <FormattedMessage id="CategoryFilter.rentals" />
          </div>
          <div className={css.categoryList}>
            {options.map(category => (
              <CategoryFilterPrimaryItem
                key={category.key}
                category={category}
                selectedKey={selectedKey || initialCategory?.key}
                onSelect={setSelectedKey}
              />
            ))}
          </div>
          <div className={css.bottomSection}>
            <div className={css.clearButton} onClick={handleClear}>
              <FormattedMessage id="CategoryFilter.clear" />
            </div>
            <div className={css.applyCancelButtons}>
              <button className={css.cancelButton} onClick={handleCancel}>
                <FormattedMessage id="CategoryFilter.cancel" />
              </button>
              <button className={css.applyButton} onClick={handleApply}>
                <FormattedMessage id="CategoryFilter.apply" />
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default injectIntl(CategoryFilter);
