const QUERY_VALUE_DELIMITER = ',';

export const getCategoryByKey = (list, key) => {
    if (!key) return;
    const keyValue = key.toLowerCase();
    for (let i = 0; i < list.length; i++) {
        const categoryItem = list[i];
        if (categoryItem.key.toLowerCase() === keyValue) {
            return categoryItem;
        }
        if (categoryItem.items) {
            const childItem = getCategoryByKey(categoryItem.items, keyValue);
            if (childItem) return childItem;
        }
    }
}

export const getQueryValueForCategory = (category) => {
    return category.items?.length > 0
        ? category.items.map(item => item.key).sort().join(QUERY_VALUE_DELIMITER).toLowerCase()
        : category.key.toLowerCase();
}

export const getCategoryByQueryValue = (list, queryValue) => {
    if (!queryValue) return;
    const keys = queryValue.split(QUERY_VALUE_DELIMITER);
    if (keys.length === 1) {
        return getCategoryByKey(list, keys[0]);
    } else {
        const orderedValue = keys.sort().join(QUERY_VALUE_DELIMITER).toLowerCase();
        const parentCategory = list.find(categoryItem => {
            const queryValue = getQueryValueForCategory(categoryItem);
            return queryValue === orderedValue;
        });
        return parentCategory ?? getCategoryByKey(list, keys[0]);
    }
}