import React, { useCallback } from 'react';
import classNames from 'classnames';
import { string, object, func } from 'prop-types';
import css from './CategoryFilter.module.css';

export const CategoryFilterItem = (props) => {
    const { category, selectedKey, className, onSelect } = props;
    
    const classes = classNames(css.category, className, {
        [css.selectedCategory]: selectedKey === category.key
    });

    const handleSelect = useCallback(() => {
        onSelect(category.key);
    }, [category.key, onSelect]);

    return (
        <div className={classes} onClick={handleSelect}>
            {category.label}
        </div>
    );
}

CategoryFilterItem.propTypes = {
    category: object.isRequired,
    selectedKey: string,
    className: string,
    onSelect: func.isRequired
};

export default CategoryFilterItem;
