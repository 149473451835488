import React, { useCallback, useMemo, useState } from 'react';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import Modal from '../Modal/Modal';
import Icon from '../Icon/Icon';
import css from './SearchMobileFilters.module.css';
import { generateQueryValue, getFilterConfig, parseInitialValues } from './SearchMobileFilters.helpers';
import { FieldCheckbox } from '..';
import { FilterForm } from '../../forms';

export const SearchMobileFilters = (props) => {
    const {
        options,
        initialValues,
        onSelect,
        onManageDisableScrolling,
        getHandleChangedValueFn,
        intl
    } = props;
    const [isOpen, setIsOpen] = useState(false);

    const quantityConfig = useMemo(() => getFilterConfig('quantity'), []);
    const initialQuantities = useMemo(
        () => parseInitialValues(initialValues, quantityConfig),
        [initialValues?.([quantityConfig.paramName]), quantityConfig]
    );

    const deliveryConfig = useMemo(() => getFilterConfig('delivery'), []);
    const initialDelivery = useMemo(
        () => parseInitialValues(initialValues, deliveryConfig),
        [initialValues?.([deliveryConfig.paramName]), deliveryConfig]
    );

    const formInitialValues = useMemo(() => {
        const keys = initialQuantities.concat(initialDelivery).map((option) => option.key);
        const result = {};
        for(var i = 0; i < keys.length; i++) {
            result[keys[i]] = [keys[i]];
        }
        return result;
    }, [initialQuantities, initialDelivery]);

    const modalOpenerClassName = initialQuantities.length > 0 || initialDelivery.length > 0
        ? css.modalOpenerSelected
        : css.modalOpener;

    const openModal = useCallback(() => {
        setIsOpen(true);
    }, []);

    const handleClear = useCallback(() => {
        setIsOpen(false);
        handleSubmit([]);
    }, [onSelect]);

    const handleSubmit = useCallback((values) => {
        setIsOpen(false);
        const quantityQueryValue = generateQueryValue(values, quantityConfig);
        const deliveryQueryValue = generateQueryValue(values, deliveryConfig, `has_any:`);
        const result = {
            [quantityConfig.paramName]: quantityQueryValue,
            [deliveryConfig.paramName]: deliveryQueryValue
        };
        getHandleChangedValueFn(true)(result);
    }, [options, onSelect]);

    const handleCancel = useCallback(() => {
        setIsOpen(false);
    }, []);

    return (
        <>
            <div className={css.root}>
                <button
                    className={modalOpenerClassName}
                    onClick={openModal}
                >
                    <Icon className={css.threeBarsIcon} iconID="threeBars" />
                </button>
            </div>
            <Modal
                id="mobileFiltersModal"
                isOpen={isOpen}
                closeButtonMessage={' '}
                scrollLayerClassName={css.modalLayer}
                containerClassName={css.modalContainer}
                closeButtonClassName={css.modalClose}
                onManageDisableScrolling={onManageDisableScrolling}
                onClose={handleCancel}
                usePortal
                showCloseButton
            >
                <div>
                    <div className={css.modalTitle}>
                        <FormattedMessage id="SearchMobileFilter.filters" />
                    </div>
                    <div className={css.modalContent}>
                        <FilterForm
                            initialValues={formInitialValues}
                            onSubmit={handleSubmit}
                            onCancel={handleCancel}
                            onClear={handleClear}
                        >
                            <div className={css.filterContainer}>
                                <div className={css.filterTitle}>
                                    <FormattedMessage id="SearchMobileFilter.quantity" />
                                </div>
                                {quantityConfig.options.map((option) => (
                                    <div key={option.key} className={css.filterItem}>
                                        <FieldCheckbox
                                            id={option.key}
                                            key={option.key}
                                            name={option.key}
                                            label={option.label}
                                            value={option.key}
                                        />
                                    </div>
                                ))}
                            </div>
                            <div className={css.filterContainer}>
                                <div className={css.filterTitle}>
                                    <FormattedMessage id="SearchMobileFilter.delivery" />
                                </div>
                                {deliveryConfig.options.map((option) => (
                                    <div key={option.key} className={css.filterItem}>
                                        <FieldCheckbox
                                            id={option.key}
                                            key={option.key}
                                            name={option.key}
                                            label={option.label}
                                            value={option.key}
                                        />
                                    </div>
                                ))}
                            </div>
                        </FilterForm>
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default injectIntl(SearchMobileFilters);