import { arrayOf, func, number, object, string } from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import { FieldCheckbox } from '..';
import { injectIntl, intlShape } from '../../util/reactIntl';
import FilterPopup from '../FilterPopup/FilterPopup';

import css from './DeliveryFilter.module.css';

export const DeliveryFilter = (props) => {
    const {
        id,
        label,
        options,
        initialValues,
        queryParamNames,
        onSelect,
        contentPlacementOffset,
        intl
    } = props;

    const queryParamName = Array.isArray(queryParamNames) ? queryParamNames[0] : queryParamNames;
    const initialOptions = useMemo(() => {
        const value = initialValues?.[queryParamName];
        const keys = value?.replace('has_any:', '').split(',') ?? [];
        return options.filter(option => keys.includes(option.key));
    }, [initialValues?.[queryParamName], options]);

    const initialOrSelectedLabel = initialOptions.map(option => option.label).join(', ') || label;
    const isSelected = initialOptions.length > 0;

    const formInitialValues = useMemo(() => {
        const keys = initialOptions.map((option) => option.key);
        const result = {};
        for(var i = 0; i < keys.length; i++) {
            result[keys[i]] = [keys[i]];
        }
        return result;
    }, [initialOptions]);

    const handleSubmit = useCallback((values) => {
        const keys = values
            ? Object.keys(values).filter(key => values[key].length === 1)
            : [];
        const queryValue = keys.length > 0 ? `has_any:${keys.join(',')}` : '';
        onSelect({ [queryParamName]: queryValue });
    }, [queryParamName, onSelect]);

    return (
        <FilterPopup
            popupClassName={css.popupSize}
            label={initialOrSelectedLabel}
            iconId="delivery"
            isSelected={isSelected}
            id={`${id}.popup`}
            showAsPopup
            contentPlacementOffset={contentPlacementOffset}
            onSubmit={handleSubmit}
            initialValues={formInitialValues}
        >
            <div className={css.container}>
                {options.map((option) => (
                    <div key={option.key}>
                        <FieldCheckbox
                            id={option.key}
                            key={option.key}
                            name={option.key}
                            label={option.label}
                            value={option.key}
                        />
                    </div>
                ))}
            </div>
        </FilterPopup>
    );
}

DeliveryFilter.defaultProps = {
    initialValues: null,
    contentPlacementOffset: 0,
  };
  
  DeliveryFilter.propTypes = {
    id: string.isRequired,
    label: string,
    queryParamNames: arrayOf(string).isRequired,
    onSelect: func.isRequired,
    initialValues: object,
    contentPlacementOffset: number,
  
    intl: intlShape.isRequired,
  };

export default injectIntl(DeliveryFilter);